
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {},
  setup () {
    const store = useStore()
    const router = useRouter()

    const fields = ref([
      {
        title: 'Product Name',
        dataIndex: 'product_name',
      },
      {
        title: 'Product Category',
        dataIndex: 'product_category.product_category_name',
      },
      {
        title: 'Product Type',
        dataIndex: 'product_type.product_type_name',
      },
      // {
      //   title: "For Coastal DOI",
      //   dataIndex: "volume_threshold",
      //   align: 'center',
      //   slots: { customRender: "for_coastal_doi" }
      // },
      // {
      //   title: "For Selective PriceOff",
      //   dataIndex: "volume_threshold",
      //   align: 'center',
      //   slots: { customRender: "for_selective_priceoff" }
      // },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('Product/index', payload)
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const createRecord = () => {
      router.push('/product/create')
    }

    const editRecord = (record: any) => {
      router.push(`/product/edit/` + record.id)
    }

    const deleteRecord = (record: any) => {
      store.dispatch('Product/destroy', record.id)
    }

    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    const items = computed(() => store.getters['Product/items'])
    const loading = computed(() => store.getters['Product/status'])

    onMounted(index)
    onMounted(scrollToTop)

    return {
      loading,
      items,
      fields,
      filterRecord,
      createRecord,
      editRecord,
      deleteRecord
    }
  },
})
